import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/_App/layout'
import Seo from '../../components/_App/seo'
import TopHeader from '../../components/Common/TopHeader'
import Navbar from '../../components/_App/Navbar'
import Why from '../../components/Products/Why'
import ProductBanner from '../../components/Products/ProductBanner'
import What from '../../components/Products/What'
import Footer from '../../components/_App/Footer'
import TalkArea from '../../components/Common/TalkArea'
import FaqArea from '../../components/FAQ/FaqArea'
import Pricing from '../../components/Products/Pricing'

import Data from '../../components/Data/Data'

const myQuery = graphql`
  query GetInnoSalesFeatureDetails {
    feature: strapiProductFeature(productId: { eq: "innosales" }) {
      title
      helpText
      featureCard {
        icon
        shortDesc
        title
      }
    }
  }
`

const Innosales = () => {
  const {
    feature: { helpText, title, featureCard },
  } = useStaticQuery(myQuery)

  return (
    <Layout>
      <Seo
        title="InnoSales - Create, Share and Grow"
        description="Create a Professional-looking product catalogue and showcase your product digitally using a customizable template in just 3 simple clicks"
        image="https://res.cloudinary.com/innoage/image/upload/v1653573151/large_home_3_d6be8e5fe6.png"
      />

      <TopHeader />

      <Navbar />

      <ProductBanner
        image="https://res.cloudinary.com/innoage/image/upload/v1667123923/innosales_devices_odd4ns.png"
        name="InnoSales - B2B Platform"
        description="Create professional looking e-Catalogue to showcase your product inventory digitally, to track queries, getting more buyers, tracking all the activities on your products & much more"
        url="https://innosales.in/login"
      />

      <Why why={Data.whyInnosales} />

      <What helpText={helpText} title={title} featureCard={featureCard} />

      <TalkArea />

      <Pricing tagline="Create, Share and Grow" pricingDetails={Data.innosalesPricing} />

      <FaqArea
        description="We at InnoAge always believe in Transparency with our clients & employees, please check
            list of frequently asked questions. Feel free to ask if this doesn't help."
        faq1={Data.faqis1}
        faq2={Data.faqis2}
      />

      <Footer />
    </Layout>
  )
}

export default Innosales
